<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="科室设置"/>
        <el-row style="margin-top: 40px">
            <el-form ref="form" :model="form" label-width="150px" :rules="rules">
                <el-form-item label="医院名称">
                    <el-input v-model="form.name" style="width:50%">
                    </el-input>
                </el-form-item>
                <el-form-item label="科室名称：">
                    <el-input v-model="form.dept_name" style="width:50%">
                    </el-input>
                </el-form-item>
                <el-form-item label="联系电话：">
                    <el-input v-model="form.phone" style="width:50%">
                    </el-input>
                </el-form-item>
                <el-form-item label="地理位置：" >
                    <div class="map" >
                        <el-input v-model="dataform.address" placeholder="请输入地址来直接查找相关位置" style="width: 50%"></el-input>
                        <baidu-map  class="bm-view"
                                    :center="center"
                                    :zoom="zoom"
                                    :scroll-wheel-zoom="true"
                                    @click="getLocationPoint"
                                    >
                            <bm-view style="width: 50%; height:330px; flex: 1;border: 1px solid #DCDFE6;;margin-top: 5px"></bm-view>
                            <bm-marker :position="{lng: lng, lat: lat}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
                            </bm-marker>
                            <bm-local-search :keyword="dataform.address" :auto-viewport="true" style="width:0px;height:0px;overflow: hidden;"></bm-local-search>
                        </baidu-map>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" @click="addSubmit" style="width:120px;">保存
                    </el-button>
                    <el-button type="info" size="medium" @click="onReset" style="width:120px;">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                keywords: '',
                model1: false,
                form: {
                    name: '',
                    dept_name: '',
                    phone: '',
                    location_name: '',
                    longitude: '',
                    latitude: ''
                },
                lat:null,
                lng:null,
                BMap:null,
                map:null,
                center: {lng: 109.45744048529967, lat: 36.49771311230842},
                zoom: 14,
                rules: {
                    hospital: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            handlerBMap({BMap, map}) {
                this.BMap = BMap
                this.map = map
            },
            makerCenter(lng, lat) {
                var that = this
                if (that.map && lng) {
                    that.map.clearOverlays()
                    var pt = new that.BMap.Point(lng, lat);
                    that.map.addOverlay(new that.BMap.Marker(pt))
                    that.center.lng = lng
                    that.center.lat = lat
                    that.zoom = 15
                }
            },
            getLocationPoint(e) {
                console.log(e)
                this.lng = e.point.lng;
                this.lat = e.point.lat;
                this.dataform.longitude = e.point.lng;
                this.dataform.latitude = e.point.lat;
                // eslint-disable-next-line no-undef
            //     let geoCoder = new BMap.Geocoder();
            //     geoCoder.getLocation(e.point, res => {
            //         console.log(res);
            //         if(res.surroundingPois.length) {
            //             this.dataform.address = res.surroundingPois[0].address
            //         }
            //     })
            },
            onReset() {
                this.form = {
                    depname: '',
                    hospital: '',
                    phone: '',
                }
            },
            async addSubmit() {
                const resp = await this.$http.put('/surgery/origin/', this.form)
                if (resp.data.code == 200) {
                    this.$message.success("保存成功")
                } else {
                    this.$message.error(resp.data.msg)
                }
            },
            async getData() {
                const resp = await this.$http.get('/surgery/origin/')
                console.log(resp)
                if (resp.data.data.length > 0) {
                    // this.form = resp.data.data[0]
                    this.form.ID = resp.data.data[0].ID
                    this.form.name = resp.data.data[0].name
                    this.form.dept_name = resp.data.data[0].dept_name
                    this.form.phone = resp.data.data[0].phone
                    this.form.longitude = resp.data.data[0].longitude
                    this.form.latitude = resp.data.data[0].latitude
                    this.makerCenter(this.form.longitude, this.form.latitude)
                    // this.form.location_name= resp.data.data[0].location_name
                    // Object.assign(this.form, resp.data.data[0], {
                    //     name: '',
                    // })
                    console.log(resp.data.data[0])
                }
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    /deep/ .BMap_cpyCtrl {
        display: none;
    }

    /deep/ .anchorBL {
        display: none;
    }
    .serachinput{
        width: 300px;
        height: 38px;
        box-sizing: border-box;
        padding: 9px;
        border: 1px solid #dddee1;
        line-height: 20px;
        font-size: 16px;
        color: #333;
        position: relative;
        border-radius: 4px;
        -webkit-box-shadow: #666 0px 0px 10px;
        -moz-box-shadow: #666 0px 0px 10px;
        box-shadow: #666 0px 0px 10px;
    }
</style>
